<template>
  <div>
    <h2 class="border-bottom pb-2 mb-3">
      <i class="fa fa-bullseye"></i> Adalytics Report
    </h2>
    <div style="background-color: white; padding: 2%">
      <div v-if="view !== 'video'">
        <div class="row">
          <div class="row col-12">
            <div
              class="
                form-group
                col-xl-10 col-lg-10 col-xs-12 col-sm-12 col-md-12 col-auto
              "
            >
              <form class="col-12" @submit.prevent="load">
                <div class="row">
                  <div class="input-group col-xl-2 col-lg-2 col-sm-6 col-md-6">
                    <div class="input-group-prepend">
                      <span class="input-group-text">View</span>
                    </div>
                    <select class="form-control" v-model="form.view">
                      <option value="">Default</option>
                      <option value="summary">Summary</option>
                      <option value="state">State</option>
                      <option value="city">City</option>
                      <option value="hour">Hour</option>
                      <option value="domain">Domain</option>
                      <option value="qso">QSO</option>
                    </select>
                  </div>

                  <div
                    class="
                      input-group
                      col-xl-2 col-lg-2 col-xs-12 col-sm-6 col-md-6
                    "
                    v-if="!advanceFilter"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">From</span>
                    </div>
                    <input
                      type="date"
                      class="form-control"
                      v-model="form.from"
                    />
                  </div>

                  <div
                    class="
                      input-group
                      col-xl-2 col-lg-2 col-xs-12 col-sm-6 col-md-6
                    "
                    v-if="!advanceFilter"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">To</span>
                    </div>
                    <input type="date" class="form-control" v-model="form.to" />
                  </div>
                  <div
                    class="
                      input-group
                      col-xl-2 col-lg-2 col-xs-12 col-sm-6 col-md-6
                    "
                    v-if="!advanceFilter"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">Max</span>
                    </div>
                    <select class="form-control" v-model="max">
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="250">250</option>
                      <option value="500">500</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                  <div
                    class="input-group col-xl-4 col-lg-4 col-sm-6 col-md-6"
                    v-if="!advanceFilter"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">Search</span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.search"
                    />
                  </div>
                  <div
                    class="input-group col-xl-10 col-lg-10 col-sm-6 col-md-6"
                    v-if="advanceFilter"
                  >
                    <div class="input-group-prepend">
                      <span class="input-group-text">Search</span>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.search"
                    />
                  </div>
                </div>

                <!-- <input type="submit" value="Search" class="btn btn-primary btn-medium"/> -->
              </form>
            </div>
            <div class="col-xl-2 col-lg-2 col-xs-12 col-sm-12 col-md-12">
              <div class="row text-center">
                <div class="form-group" v-if="!advanceFilter">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary text-white"
                    @click.prevent="toggleAdvanceFilter"
                  >
                    Advanced
                  </button>
                </div>
                <div class="form-group" v-else>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary text-white"
                    @click.prevent="toggleAdvanceFilter"
                  >
                    Basic
                  </button>
                </div>
                <div class="form-group col-auto">
                  <button
                    type="button"
                    class="btn btn-sm btn-success text-white"
                    @click.prevent="load"
                  >
                  <i class="fa fa-search" title="Search" v-if="!sLoading"></i>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-if="sLoading"
                    ></span>

                    Search
                  </button>
                </div>
                <div class="form-group col-auto">
                  <button
                    type="button"
                    class="btn btn-sm text-white"
                    style="background-color: #dbc039"
                    @click.prevent="exportToExcel"
                  >
                  <i class="fa fa-sign-out" title="Export" v-if="!eLoading"></i>
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                      v-if="eLoading"
                    ></span>
                    Export
                  </button>
                </div>
              </div>
            </div>
            <div class="col-12" v-if="advanceFilter">
              <hr class="border-bottom" />
              <div class="form-group col-12">
                <form class="col-12">
                  <div class="row">
                    <div class="input-group col-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Domain</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.domain"
                      />
                    </div>
                    <div class="input-group col-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Tracker Id</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.tracker"
                      />
                    </div>
                    <div class="input-group col-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Node Id</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.node"
                      />
                    </div>
                    <div class="input-group col-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Ip Address</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.ip"
                      />
                    </div>
                    <div class="input-group col-3 pt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Node Ip</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.nodeIp"
                      />
                    </div>
                    <div class="input-group col-3 pt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Sort by</span>
                      </div>
                      <select class="form-control" v-model="form.sorting">
                        <option value="{_id:-1,date:-1}" selected>Date</option>
                        <option value="{date:-1,bounce:-1}">Bounce</option>
                        <option value="{date:-1,campaign_id:-1}">
                          Campaign
                        </option>
                        <option value="{date:-1,data.link:-1}">Domain</option>
                        <option value="{data.tos.seconds:-1}">tos</option>
                      </select>
                    </div>
                    <div class="input-group col-3 pt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">From</span>
                      </div>
                      <input
                        type="date"
                        class="form-control"
                        v-model="form.from"
                      />
                    </div>
                    <div class="input-group col-3 pt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">From Time</span>
                      </div>
                      <input
                        type="time"
                        class="form-control"
                        v-model="form.fromTime"
                      />
                    </div>

                    <div class="input-group col-3 pt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">To</span>
                      </div>
                      <input
                        type="date"
                        class="form-control"
                        v-model="form.to"
                      />
                    </div>
                    <div class="input-group col-3 pt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">To Time</span>
                      </div>
                      <input
                        type="time"
                        class="form-control"
                        v-model="form.toTime"
                      />
                    </div>
                    <div class="input-group col-2 pt-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Max</span>
                      </div>
                      <select class="form-control" v-model="max">
                        <option value="25" selected>25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="250">250</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-auto table-responsive" v-if="!preloader">
            <table
              class="table table-striped table-hover mt-3"
              v-if="form.view == ''"
            >
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Client</th>
                  <th scope="col">Campaign</th>
                  <th scope="col">Tracker</th>
                  <th scope="col">Domain</th>
                  <th scope="col">Node IP</th>
                  <th scope="col">IP Address</th>
                  <th scope="col">Useragent</th>
                  <th scope="col">Bounce</th>
                  <th scope="col">Pages</th>
                  <th scope="col">TOS</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in datasource.data" v-bind:key="row._id">
                  <td>{{ new Date(row.date).toLocaleTimeString() }}</td>
                  <td>{{ row.client ? row.client.Name : "N/A" }}</td>
                  <td>{{ row.campaign ? row.campaign.name : "N/A" }}</td>
                  <td>{{ row.tracker }}</td>
                  <td>{{ row.hostname }}</td>
                  <td>{{ row.node_ip }}</td>
                  <td>{{ row.proxy.remoteip || "" }}</td>
                  <td>{{ row.data ? row.data.useragent : "N/A" | useragent}} </td>
                  <td>{{ row.bounce }}%</td>
                  <td>{{ row.pages || 0 }}</td>
                  <td>{{ convertHMS(Number(row.tos || 0)) }}</td>
                  <td>
                    <button
                      @click="details(row)"
                      type="button"
                      class="btn btn-primary"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-striped table-hover mt-3"
              v-if="form.view == 'summary'"
            >
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Campaign Domain</th>
                  <th scope="col">Sessions</th>
                  <th scope="col">Bounce Rate</th>
                  <th scope="col">TOS Avg</th>
                  <th scope="col">Pages Avg</th>
                  <th scope="col">Target Geo(s)</th>
                  <th scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in datasource.data" v-bind:key="row._id">
                  <td>{{ toDate(row.date) }}</td>
                  <td>{{ row.hostname || '0.0.0.0' }}</td>
                  <td>{{ row.sessions || 0}}</td>
                  <td>{{ +(Math.round(row.bounce + "e+2")+"e-2") || 0 }}%</td>
                  <td>{{ convertHMS(Number(row.tos_avg || 0)) }}</td>
                  <td>{{ +(Math.round(row.pages + "e+2")+"e-2") || 0 }}</td>
                  <td>{{ row.target || 'Queens, NY, US' }}</td>
                  <td>
                    <button
                      @click="details(row)"
                      type="button"
                      class="btn btn-primary"
                      disabled
                    >
                      Details
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-striped table-hover mt-3"
              v-if="form.view == 'city'"
            >
              <thead>
                <tr>
                  <th>City</th>
                  <th>State</th>
                  <th>Country</th>
                  <th>Sessions</th>
                  <th>Bounce</th>
                  <th>Pages</th>
                  <th>TOS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in datasource.data" v-bind:key="row._id">
                  <td>{{ row._id.city }}</td>
                  <td>{{ row._id.state }}</td>
                  <td>{{ row._id.country }}</td>
                  <td>{{ Number(row.sessions || 0).toLocaleString() }}</td>
                  <td>{{ Number(row.bounce || 0).toFixed(2) }}%</td>
                  <td>{{ Number(row.pages || 0).toFixed(2) }}</td>
                  <td>{{ convertHMS(Number(row.tos || 0)) }}</td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-striped table-hover mt-3"
              v-if="form.view == 'hour'"
            >
              <thead>
                <tr>
                  <th>Hour</th>
                  <th>Sessions</th>
                  <th>Bounce</th>
                  <th>Pages</th>
                  <th>TOS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in datasource.data" v-bind:key="row._id">
                  <td>{{ row._id.hour }}</td>
                  <td>{{ Number(row.sessions || 0).toLocaleString() }}</td>
                  <td>{{ Number(row.bounce || 0).toFixed(2) }}%</td>
                  <td>{{ Number(row.pages || 0).toFixed(2) }}</td>
                  <td>{{ convertHMS(Number(row.tos || 0)) }}</td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-striped table-hover mt-3"
              v-if="form.view == 'domain'"
            >
              <thead>
                <tr>
                  <th>Domain</th>
                  <th>Date</th>
                  <th>Client</th>
                  <th>Campaign</th>
                  <th>Bounce</th>
                  <th>Pages</th>
                  <th>TOS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in datasource.data" v-bind:key="row._id">
                  <td>{{ row.hostname }}</td>
                  <td>{{ new Date(row.date).toLocaleTimeString() }}</td>
                  <td>{{ row.client.Name }}</td>
                  <td>{{ row.campaign.name }}</td>
                  <td>{{ row.bounce }}%</td>
                  <td>{{ row.pages || 0 }}</td>
                  <td>{{ convertHMS(Number(row.tos || 0)) }}</td>
                </tr>
              </tbody>
            </table>
            <table
              class="table table-striped table-hover mt-3"
              v-if="form.view == 'qso'"
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Country</th>
                  <th>State</th>
                  <th>City</th>
                  <th>Hour</th>
                  <th>Clicks-Needed</th>
                  <th>QSO-Count</th>
                  <th>IP</th>
                  <th>Domain</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="row in datasource.data" v-bind:key="row._id">
                  <td>{{ new Date(row.date).toLocaleTimeString() }}</td>
                  <td>{{ row.country }}</td>
                  <td>{{ row.state }}</td>
                  <td>{{ row.city }}</td>
                  <td>{{ row.hour }}</td>
                  <td>{{ row.clicks.max || 0 }}</td>
                  <td>{{ row.count || 0 }}</td>
                  <td>{{ row.ip }}</td>
                  <td>{{ row.link }}</td>
                </tr>
              </tbody>
            </table>
            <!-- <v-pag :item="datasource" :page="page" :length="12" :pageCount="Math.ceil(datasource.total/max)" :trigger="load" /> -->
            <div class="row">
              <div class="col-12 col-sm-9">
                <v-pagination
                  v-model="page"
                  :pageCount="Math.ceil(datasource.total / max)"
                  :classes="bootstrapPaginationClasses"
                  :labels="customLabels"
                  :length="12"
                  :total-visible="7"
                  @input="load"
                ></v-pagination>
              </div>
              <div class="col-12 col-sm-3 text-right">
                <span>{{ datasource.data.length * Number(page | 1) }}</span> of
                <span>{{ datasource.total }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal -->
        <div
          class="modal fade"
          id="historyModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="historyModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content text-break text-wrap">
              <div class="modal-header">
                <h5 class="modal-title" id="historyModalLabel">
                  Campaign History
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p
                  class="text-wrap text-break"
                  v-for="(item, index) in modalData.history"
                  :key="index"
                >
                  {{ item.url ? item.url : item }}
                </p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="view == 'video'" class="container-flex">
        <div class="row d-flex">
          <!--                video section-->
          <div class="col-12 col-sm-7 col-md-7 mb-1 h-25">
            <div class="p-1">
              <video controls width="1080" height="600px">
                <source
                  v-if="itemData.video"
                  :src="itemData.video"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

          <!--                tab section-->
          <div class="col-12 col-sm-5 col-md-5 bg-white">
            <button
              type="button"
              @click="view = lastView"
              class="btn btn-danger btn-sm col-12 mb-1"
            >
              Close
            </button>

            <div class="accordion" role="tablist">
              <b-card no-body class="mb-1">
                <b-button block v-b-toggle.accordion-1 squared variant="primary"
                  ><span class="d-inline-block"
                    ><i class="fa fa-info-circle sm"></i
                  ></span>
                  Session Info</b-button
                >
                <b-collapse
                  id="accordion-1"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="row p-3">
                      <div class="col-12 col-sm-12">
                        <div class="row p-2 card bg-white rounded shadow-lg">
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-calendar sm"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              Date:
                              {{ new Date(itemData.date).toLocaleTimeString() }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-user sm"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              Client: {{ itemData.client ? itemData.client.Name : "N/A" }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-flag sm"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              Campaign: {{ itemData.campaign? itemData.campaign.name : "N/A" }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-map-marker sm"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              Tracker: {{ itemData.tracker }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-hand-o-up sm"></i
                            ></span>
                            <p class="d-inline-block text-wrap text-break ml-2">
                              Node:
                            </p>
                            <p class="d-inline-block text-wrap text-break ml-2">
                              {{ itemData.node || "NA" }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-map-pin sm"></i
                            ></span>
                            <p class="d-inline-block text-wrap text-break ml-2">
                              Node IP: {{ itemData.node_ip || "NA" }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-server sm"></i
                            ></span>
                            <p class="d-inline-block text-wrap text-break ml-2">
                              Hostname: {{ itemData.hostname }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-server sm"></i
                            ></span>
                            <p class="d-inline-block text-wrap text-break ml-2">
                              Domain: {{ itemData.domain }}
                            </p>

                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-chrome sm"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              Browser: {{ itemData.data.useragent | useragent }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-map-signs sm bg-green"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              IP: {{ itemData.proxy.remoteip || "" }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-level-down sm bg-green"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              Bounce: {{ itemData.bounce }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-copy sm bg-green"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              Pages: {{ itemData.pages || 0 }}
                            </p>
                          </div>
                          <div class="col-12 col-sm-12">
                            <span class="d-inline-block"
                              ><i class="fa fa-clock-o sm bg-green"></i
                            ></span>
                            <p class="d-inline-block ml-2">
                              TOS: {{ convertHMS(Number(itemData.tos || 0)) }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-button block v-b-toggle.accordion-2 squared variant="primary"
                  ><span class="d-inline-block"
                    ><i class="fa fa-retweet sm"></i
                  ></span>
                  Actions
                </b-button>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="row p-3">
                      <div
                        class="col-12 col-sm-12 mb-2"
                        v-for="(item, index) in itemData.events"
                        :key="index"
                      >
                        <div class="accordion shadow-lg" role="tablist">
                          <b-card no-body class="mb-1">
                            <b-button
                              v-b-toggle="`collapse-${index}`"
                              class="text-left"
                              variant="light"
                              >{{ item.type }}</b-button
                            >

                            <b-collapse
                              :id="`collapse-${index}`"
                              visible
                              :accordion="'my-accordions'"
                              role="tabpanels"
                            >
                              <b-card-body>
                                <div
                                  class="d-flex w-100 justify-content-between"
                                >
                                  <b-card-text>
                                    <small>{{
                                      new Date(item.date).toLocaleTimeString()
                                    }}</small></b-card-text
                                  >
                                </div>
                                <b-card-text>
                                  <p class="mb-1">
                                    {{ item.message }}
                                  </p></b-card-text
                                >
                              </b-card-body>
                            </b-collapse>
                          </b-card>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card no-body class="mb-1">
                <b-button block v-b-toggle.accordion-3 squared variant="primary"
                  ><span class="d-inline-block"
                    ><i class="fa fa-history sm"></i
                  ></span>
                  History</b-button
                >
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div class="row p-3">
                      <div
                        class="col-12 col-sm-12 mb-2"
                        v-for="(item, index) in itemData.data.history"
                        :key="index"
                      >
                        <div class="card bg-white rounded shadow-lg">
                          <div class="card-body">
                            <p class="text-wrap text-break">
                              {{ item.url ? item.url : item }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueChartsCSS from "vue.charts.css";
import axios from "axios";
import vPagination from "vue-plain-pagination";
import moment from "moment-timezone";
//import swal from "sweetalert";

Vue.use(VueChartsCSS);

export default {
  name: "analytics",
  props: ["user"],
  components: {
    vPagination,
  },
  data: function () {
    return {
      datasource: { data: [], total: 0 },
      form: { from: "", to: "", view: "", domain: "", sorting: { date: -1 } },
      preloader: false,
      sLoading: false,
      eLoading: false,
      processing: false,
      advanceFilter: false,
      max: 25,
      view: "default",
      lastView: "",
      itemData: null,
      page: 1,
      bootstrapPaginationClasses: {
        ul: "pagination",
        li: "page-item",
        liActive: "active",
        liDisable: "disabled",
        button: "page-link",
      },
      customLabels: {
        first: "First",
        prev: "Previous",
        next: "Next",
        last: "Last",
      },
      modalData: { history: "" },
      modalVideo: { file: "" },
    };
  },
  created: function () {
    this.load();
    window.addEventListener("keydown", this.windowListener);
    this.$root.preloader = false;
  },
  computed: {
    getView() {
      return this.form.view;
    },
  },
  watch: {
    getView(newVal, oldVal) {
      // console.log(`View change event: old[${oldVal}], new[${newVal}], `, this);
      if (oldVal !== newVal) {
        this.load();
      }
    },
  },
  beforeDestroy() {},
  destroyed() {
    window.removeEventListener("keydown", this.windowListener);
  },
  methods: {
    windowListener: function (e) {
      if (e.keyCode === 13 && !this.processing) {
        this.load();
      }
    },
    convertHMS: (value) => {
      const sec = parseInt(value, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
      let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
    },
    serialize: function(obj) {
      var str = [];
      for (var p in obj)
          if (obj.hasOwnProperty(p)) {
              str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
          }
      return str.join("&");
    },
      exportFile: function(response){
        console.log(response.data)
          let blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `export-${new Date().toLocaleDateString()}.csv`;
          link.click();
      },
    exportToExcel: function () {
      // this.$root.preloader = true;
      //Load the catchers
      var request = Object.assign({ apikey: this.$root.apikey }, this.form);

      this.eLoading = true;
       this.preloader = this.$root.preloader = true;

      return axios
        .get(`${this.$root.serverUrl}/static/ga/export`, {
          params: request,
          json: true,
        })
        .then(async (resp) => {
            this.exportFile(resp)
          if (resp == undefined || resp.length == 0) {
            // this.datasource = { total: 0, data: [] };
            this.eLoading = false;
               this.preloader = this.$root.preloader = false;
          } else {
              //request = this.serialize(resp);
              //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
              //this.eLoading = false;
             // this.preloader = this.$root.preloader = false;
          }
          this.eLoading = false;
           this.preloader = this.$root.preloader = false;
        });
    },
    load: function () {
      //  this.$root.preloader = true;
      //Load the catchers
      this.page = this.page < 1 ? 1 : this.page;
      var page = 0;
      if (
        event &&
        event.type == "click" &&
        event.currentTarget &&
        event.currentTarget.className.indexOf(
          this.bootstrapPaginationClasses.button
        ) > -1
      ) {
        //Pagination triggered event
        console.log(
          `Pagination event: ${this.page}: ${event.currentTarget.className}`
        );
        page = this.page ? (this.page === 1 ? 0 : Number(this.page - 1)) : 0;
      } else page = 0;
      var request = Object.assign({ apikey: this.$root.apikey }, this.form);
      request.page = page;
      request.max = this.max;
        // this.preloader = this.$root.preloader = true;
      this.sLoading = true;
      let _route = ''
      switch (this.form.view) {
        case 'qso':
          _route='/static/qso';
          break;
      
        default:
          _route='/static/ga';
          break;
      }

      return axios
        .get(`${this.$root.serverUrl}${_route}`, {
          params: request,
          json: true,
        })
        .then(async (resp) => {
          if (resp == undefined || resp.length == 0) {
            this.datasource = { total: 0, data: [] };
            this.sLoading = false;
            //  this.preloader = this.$root.preloader = false;
          } else {
            
            switch (this.form.view) {
              case 'qso':
                this.datasource = (resp.data && resp.data.Items?{data:resp.data.Items, total:resp.data.Count}:{data:resp.Items, total:resp.Count}) || { total: 0, data: [] };
                break;
            
              default:
                this.datasource = resp.data.data || { total: 0, data: [] };
                break;
            }
            this.sLoading = false;
            //  this.preloader = this.$root.preloader = false;
          }
          this.sLoading = false;
          // this.preloader = this.$root.preloader = false;
        });
    },
    toggleAdvanceFilter() {
      return (this.advanceFilter = !this.advanceFilter);
    },
    History(item) {
      this.modalData = item.data;
    },
    toDate(item) {
      return moment(item).format('MM-DD-YYYY')
    },
    details(item) {
      this.itemData = Object.assign({}, item);
      var filename =
        item.videos && item.videos.length && item.videos[0].data.filename
          ? item.videos[0].data.filename.substring(
              item.videos[0].data.filename.lastIndexOf("/") + 1
            )
          : false;
      this.itemData.video = filename
        ? `https://crawler.adretreaver.com/static/video/${filename}`
        : false;
      this.lastView = this.view.toString();
      this.view = "video";
    },
    Video(item) {
      var filename = item.videos[0].data.filename
        ? item.videos[0].data.filename.substring(
            item.videos[0].data.filename.lastIndexOf("/") + 1
          )
        : { file: "" };

      window.location.href = `https://crawler.adretreaver.com/static/video/${filename}`;
    },
  },
};
</script>

<style scoped>
video {
  width: 100%;
  max-height: 100%;
}
#analytics .charts-css.bar {
  height: 200px;
  max-width: 300px;
  margin: 0 auto;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 614px;
    margin: 1.75rem auto;
  }
}
</style>
